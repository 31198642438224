import React from "react";
import Title from "../common/Title";
import Text from "../common/Text";
import Image from "../common/CloudinaryImage";

const Screenshots = ({ className, item }) => {
    // Montamos array con imágenes
    const screenshots = [];
    for (let i = 1; i <= item.screenshots; i++) {
        screenshots.push(i);
    }

    return (
        <>
            <div className={`${className}`}>
                <div className="container lg:px-0 py-20">
                    <Title className="text-left">Capturas</Title>
                    <Text>
                        Aquí puedes ver algunas imágenes de la aplicación en funcionamiento.
                    </Text>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mt-10 gap-10">
                        {screenshots.map(i => {
                            return (
                                <div key={i} className="shadow rounded-lg overflow-hidden">
                                    <Image
                                        alt={`${item.name} screenshot ${i}`}
                                        filename={`apps/${item.name}/screenshot${i}`}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Screenshots;
