import React from "react";
// import { FiArrowRightCircle } from "react-icons/fi";
import Text from "@components/common/Text";

const TextApp = () => {
    return (
        <>
            <Text>
                La app que te ayudará a preparar tu consolidación de empleo a Correos de una forma
                dinámica y sencilla. Podrás practicar lo que has estudiado en cualquier lugar y en
                cualquier momento.
                <br />
                <br />
                iCorreos dispone de la mayor base de datos con más de{" "}
                <strong>9.500 preguntas actualizadas</strong> a fecha de hoy, distribuidas entre los{" "}
                <strong>12 temas de la convocatoria</strong>.
                <br />
                <br />
                Usa las diferentes formas de juego como las más difíciles o las más falladas y
                avanza en la clasificación compitiendo contra otros usuarios que se preparan para el
                examen de Correos.
                <br />
                <br />
                Pon a prueba tus conocimientos realizando tests por temas,{" "}
                <strong>simulacros de examen</strong> o con{" "}
                <strong>exámenes oficiales de convocatorias anteriores</strong>.
            </Text>
        </>
    );
};

export default TextApp;
