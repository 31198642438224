import React from "react";
import { FiArrowRightCircle } from "react-icons/fi";
import Text from "@components/common/Text";

const TextApp = () => {
    return (
        <>
            <Text>
                Descarga iGuB y colócate entre los mejores en el examen cultural y de temario de tu
                oposición de policías con nuestra aplicación de simulacros de exámenes tipo test.
                <br />
                <br />
                <ul className="ml-0 mb-3 mt-5">
                    <li>
                        <FiArrowRightCircle className="w-4 h-4 inline mr-2 text-c1" />
                        Practica lo que estás estudiando
                    </li>
                    <li>
                        <FiArrowRightCircle className="w-4 h-4 inline mr-2 text-c1" />
                        Refuerza tus puntos débiles
                    </li>
                    <li>
                        <FiArrowRightCircle className="w-4 h-4 inline mr-2 text-c1" />
                        Averigua a qué te enfrentas con exámenes reales de años anteriores
                    </li>
                    <li>
                        <FiArrowRightCircle className="w-4 h-4 inline mr-2 text-c1" />
                        Practica donde y cuando quieras con tu móvil y/o tableta
                    </li>
                    <li>
                        <FiArrowRightCircle className="w-4 h-4 inline mr-2 text-c1" />
                        Amplía conocimientos de forma interactiva y dinámica
                    </li>
                </ul>
                Actualidad, Temario, Cultura General, Psicotècnicos, Lengua Inglesa, etc.
                <br />
                <br />
                Haz realidad tus sueños y consigue tu plaza.
            </Text>
        </>
    );
};

export default TextApp;
