import React from "react";
import { FiArrowRightCircle } from "react-icons/fi";
import Text from "@components/common/Text";

const TextApp = () => {
    return (
        <>
            <Text>
                Descarga iMossos y colócate entre los mejores en el examen con nuestra aplicación de
                simulacros de exámenes tipo test.
                <br />
                <br />
                La aplicación más completa para acceder a Mossos d'Esquadra y ascender a cabo.
                <ul className="ml-0 mb-2 mt-5">
                    <li>
                        <FiArrowRightCircle className="w-4 h-4 inline mr-2 text-c1" />
                        Practica lo que estás estudiando
                    </li>
                    <li>
                        <FiArrowRightCircle className="w-4 h-4 inline mr-2 text-c1" />
                        Refuerza tus puntos débiles
                    </li>
                    <li>
                        <FiArrowRightCircle className="w-4 h-4 inline mr-2 text-c1" />
                        Averigua a qué te enfrentas con exámenes reales de años anteriores
                    </li>
                    <li>
                        <FiArrowRightCircle className="w-4 h-4 inline mr-2 text-c1" />
                        Practica donde y cuando quieras con tu móvil y/o tableta
                    </li>
                    <li>
                        <FiArrowRightCircle className="w-4 h-4 inline mr-2 text-c1" />
                        Amplía conocimientos de forma interactiva y dinámica
                    </li>
                </ul>
                <br />
                <ul className="ml-0 mb-2 mt-2">
                    <li>
                        <FiArrowRightCircle className="w-4 h-4 inline mr-2 text-c1" />
                        Mossos d'Esquadra
                    </li>
                    <li>
                        <FiArrowRightCircle className="w-4 h-4 inline mr-2 text-c1" />
                        Caporal/a Mossos d'Esquadra
                    </li>
                    <li>
                        <FiArrowRightCircle className="w-4 h-4 inline mr-2 text-c1" />
                        Psicotècnicos
                    </li>
                    <li>
                        <FiArrowRightCircle className="w-4 h-4 inline mr-2 text-c1" />
                        Actualidad
                    </li>
                </ul>
                <br />
                Haz realidad tus sueños y consigue plaza en la Policia de la Generalitat-Mossos
                d'Esquadra.
            </Text>
        </>
    );
};

export default TextApp;
