import React from "react";
import { FiArrowRightCircle } from "react-icons/fi";
import Text from "@components/common/Text";

const TextApp = () => {
    return (
        <>
            <Text>
                Apréndete la ciudad con la app iBarcelona.
                <br />
                <strong>Pon a prueba tus conocimientos</strong> con este <strong>juego quiz</strong>{" "}
                con el que aprenderás todo sobre la <strong>Ciudad Condal</strong> de forma amena.
                <br />
                <strong>Desafía a tus amigos y oponentes</strong>; responde a las preguntas de entre
                8 categorías a elegir y demuestra que eres el que más sabe sobre Barcelona.
                <br />
                <ul className="ml-0 mb-5 mt-5">
                    <li>
                        <FiArrowRightCircle className="w-4 h-4 inline mr-2" />
                        Miles de preguntas tipo test.
                    </li>
                    <li>
                        <FiArrowRightCircle className="w-4 h-4 inline mr-2" />
                        Juega donde y cuando quieras con tu móvil o tableta.
                    </li>
                    <li>
                        <FiArrowRightCircle className="w-4 h-4 inline mr-2" />
                        Amplía tus conocimientos de forma interactiva y dinámica.
                    </li>
                    <li>
                        <FiArrowRightCircle className="w-4 h-4 inline mr-2" />5 temáticas a elegir:
                        Distritos, Transportes, Puntos de Interés, Historia y Consistorio.
                    </li>
                </ul>
                <strong>Ideal como complemento</strong> en la preparación de oposiciones al
                Ayuntamiento de Barcelona.
            </Text>
        </>
    );
};

export default TextApp;
