import React from "react";
import Text from "@components/common/Text";

const TextApp = () => {
    return (
        <>
            <Text>
                Descarga iPolicía Madrid y prepárate para destacar en tu oposición para acceder al
                cuerpo de la policía municipal de Madrid.
                <br />
                <br />
                La aplicación más completa, rápida y económica para acceder en tiempo récord.
                <br />
                <br />
                Una herramienta básica que te ayudará a conseguir tu sueño.
                <br />
                <br />
                <b>
                    Personaliza tu preparación con módulos descargables para adquirir solo lo que
                    necesitas:
                </b>
                <ul className="ml-0 mb-3 mt-5">
                    <li>
                        <span role="img" aria-labelledby="">
                            ✅
                        </span>{" "}
                        Estructura de las bases oficiales del temario del acceso a la policía
                        municipal de Madrid con más de 200 preguntas por tema.
                    </li>
                    <li>
                        <span role="img" aria-labelledby="">
                            ✅
                        </span>{" "}
                        Exámenes de conocimientos oficiales de convocatorias anteriores.
                    </li>
                    <li>
                        <span role="img" aria-labelledby="">
                            ✅
                        </span>{" "}
                        Tests psicotécnicos de capacidad verbal, numérica, espacial, razonamiento y
                        atención-percepción.
                    </li>
                    <li>
                        <span role="img" aria-labelledby="">
                            ✅
                        </span>{" "}
                        Lengua inglesa clasificada por niveles de dificultad y con exámenes
                        oficiales de anteriores convocatorias.
                    </li>
                    <li>
                        <span role="img" aria-labelledby="">
                            ✅
                        </span>{" "}
                        Módulo exclusivo con las 500 preguntas de temario imprescindibles, con una
                        única respuesta para acceder al cuerpo de la Policía Municipal de Madrid.
                    </li>
                </ul>
                <br />
                <br />
                <b>¿Qué obtendrás?</b>
                <br />
                <br />
                ▶ Practica lo que estás estudiando.
                <br />
                ▶︎ Refuerza tus puntos débiles.
                <br />
                ▶︎ Averigua a qué te enfrentas con exámenes reales de años anteriores.
                <br />
                ▶︎ Estudia donde y cuando quieras con tu móvil y/o tableta.
                <br />
                ▶︎ Amplía tus conocimientos de manera interactiva y dinámica.
                <br />
                <br />
                <b>Las mejores características:</b>
                <br />
                <br />
                <span role="img" aria-labelledby="">
                    ⚕️
                </span>{" "}
                RÁNKINGS
                <br />
                <br />
                Compárate con el resto de usuarios y comprueba tu progreso. Tendrás una imagen
                cercana de tu estado en la preparación en esta oposición.
                <br />
                <br />
                <span role="img" aria-labelledby="">
                    ⚕️
                </span>{" "}
                ORDENADO POR TEMAS
                <br />
                <br />
                Podrás prepararte temas concretos para reforzar las áreas que necesites. La mejor
                manera de mejorar tu preparación en la Policía.
                <br />
                <br />
                <span role="img" aria-labelledby="">
                    👮🏻‍♂️
                </span>{" "}
                SIMULACIONES DE EXAMEN
                <br />
                <br />
                Tienes a tu disposición un simulador de examen para que puedas comprobar, antes de
                presentarte al examen, cómo sería un posible examen.
                <br />
                <br />
                <span role="img" aria-labelledby="">
                    📋
                </span>{" "}
                EXÁMENES OFICIALES
                <br />
                <br />
                Además, podrás practicar con exámenes de anteriores convocatorias.
                <br />
                <br />
                <span role="img" aria-labelledby="">
                    🤝
                </span>{" "}
                ACTUALIZADO AL MÁXIMO
                <br />
                <br />
                No hay ninguna aplicación más actualizada que esta. Somos el mejor aliado para que
                puedas aprobar esta oposición.
                <br />
                <br />
                <span role="img" aria-labelledby="contacto">
                    🩺
                </span>{" "}
                CONTACTO PERMANENTE
                <br />
                <br />
                No te dejamos solo. Te acompañamos durante todo el proceso de preparación para
                conseguir tu objetivo.
                <br />
                <br />
                Haz realidad tu sueño y asegura tu futuro en el ámbito de la Seguridad.
            </Text>
        </>
    );
};

export default TextApp;
