import React from "react";
import { AiOutlineApple, AiOutlineAndroid } from "react-icons/ai";
import Title from "../common/Title";
import Text from "../common/Text";

const DownloadApp = ({ android, ios, className }) => {
    return (
        <>
            <section className={`${className}`}>
                <div className="container lg:px-0 py-20">
                    <Title className="text-center">¿Cómo descargar la aplicación?</Title>
                    <Text className="text-center">
                        Accede a la store haciendo clic en el botón iOS o Android y descarga la app.
                        Una vez instalada podrás descargar los módulos que te más te interesen.
                    </Text>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 mt-10 md:mx-24">
                        {ios && (
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={ios}
                                className="border-4 border-ccyan rounded-lg items-center py-5 text-center font-montserrat text-2xl text-ccyan font-semibold hover:bg-ccyan hover:text-white duration-300 opacity-75"
                            >
                                <AiOutlineApple className="w-12 h-12 inline mr-5" />
                                iOS
                            </a>
                        )}

                        {android && (
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={android}
                                className="border-4 border-cpurple rounded-lg items-center py-5 text-center font-montserrat text-2xl text-cpurple font-semibold hover:bg-cpurple hover:text-white duration-300 opacity-75"
                            >
                                <AiOutlineAndroid className="w-12 h-12 inline mr-5" />
                                Android
                            </a>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
};

export default DownloadApp;
